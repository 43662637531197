import * as React from "react";
import { graphql } from "gatsby";
import Post from "../components/layouts/Post";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Helmet } from "react-helmet"

const BlogPost = (props) => {
  console.log("props:", props);
  const data = props.data;
  return (
    <Post
      pageTitle={data.mdx.frontmatter.title}
      date={data.mdx.frontmatter.date}
      authors={""}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title> Dana Calacci - {data.mdx.frontmatter.title}</title>
      </Helmet>
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Post>
  );
};

export const query = graphql`
  query GetPostBySlug($slug: String) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`;

export default BlogPost;
