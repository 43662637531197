import React, { useState } from "react";
import { graphql } from "gatsby";
import Header from "../../components/Header";

export default (props) => {
  console.log("props:", props);
  return (
    <>
      <Header large={false} />
      <div className="flex w-full justify-center bg-white text-black dark:bg-gray-600 dark:text-gray-100">
        <article className="flex flex-col w-full h-full sm:max-w-xl pt-20">
          <h1 className="font-bold text-4xl pb-5 pl-2">{props.pageTitle}</h1>
          <h3 className="font-bold text-3xl pl-2 text-gray-500 dark:text-gray-100">{props.authors}</h3>
          <h3 className="font-bold text-2xl pl-2 text-gray-400 dark:text-gray-300">{props.date}</h3>
          <div className="max-w-xl self-center pt-10 text-gray-800 dark:text-gray-100">{props.children}</div>
        </article>
      </div>
    </>
  );
};
